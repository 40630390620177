<template>
    <!-- Card -->
    <div v-if="!imageState" class="card" :class="row.BACKGROUND_CLASS">
      <div class="card-body pt-2 pb-2 px-3">
        <div class="row">
          <div class="col-12 pb-1 pr-1 mb-1">
            <h5
              class="card-title text-truncate mb-0 font-weight-bold pt-1 pr-3 "
              style="color: #2b2301"
              :title="`Index: ${rowIndex}`"
            >
              <b-icon
                v-if="row.order_frequency == 7"
                icon="star-fill"
                class="h5"
                style="color: orange; margin: 0; padding: 0;"
              ></b-icon>
              {{ row.STKDES }}
            </h5>
            <h6
              class="text-truncate pb-0 mb-0"
              style="color: #888; font-size: 0.9em;"
            >
              {{ row.SUPNAM_SUPPLIER != null ? row.SUPNAM_SUPPLIER : "ไม่มีข้อมูล" }}
            </h6>
          </div>

         
          <div class="col-4 mb-1 pl-2 pr-0" >
            <div
              class="bg-white p-0 d-flex p-2 align-items-center justify-content-center"
              style="height: 110px; border-radius: 0.25rem;"
               @click="selectImage"
            >
            <template v-if="isProcessing">
                <span class="loader"></span>
            </template>
            <template v-if="!isProcessing">
            <img
              :src="imageUrl || 'img/bsts.png'"
              class="w-100 h-100"
              style="object-fit: cover; object-position: center;"
              alt="Image Preview"
              @error="imageError"
            />
            <input
              type="file"
              ref="imageInput"
              class="d-none"
              accept="image/*"
              @change="handleImageChange"
            />
            </template>
            </div>
          </div>
 
          <div class="col-8 mb-1 pl-2 d-flex align-items-end">
            <div class="row">
              <!-- Icons -->
              <div class="col-12 mt-1 mb-0 pr-0">
                <div
                  v-if="row.ordered_at == null || row.ordered_at != today"
                  class="p-0 text-left text-dark"
                  style="position: relative;"
                >
                  <b-icon
                    icon="award-fill"
                    class="h4"
                    :class="{ fadex: !row.is_sell }"
                    style="cursor: pointer; margin: 0; padding: 0; margin-right: 2px;"
                    @click="toggleIsSell(rowIndex)"
                  ></b-icon>
                
                </div>
              </div>
  
              <!-- Stock Status -->
              <div class="col-12 pb-0 mb-0 pt-0 mt-1 mb-1">
                <div style="line-height: 18px;">
                  <div>
                    ขายเฉลี่ย{{ row.order_frequency == 30 ? "เดือน" : "สัปดาห์" }}ละ :
                    <strong>{{ getAvgSell(row) }}</strong>
                    {{ row.PQUCOD }}
                  </div>
                  <div>
                    จำนวนลูกค้า
                    <strong>{{ (row.CUSTOMERS?.length) }}</strong>
                   ราย
                  </div>
                </div>
              </div>
  
              <!-- Stock Level -->
              <div class="col-12 mb-1 pt-1 d-flex flex-column justify-content-center p-0 pl-4 pr-0">
                <div class="pl-1 pr-0 w-100">
                  <div
                    class="row p-0 text-center mr-2"
                    style="border: 1px solid black; border-radius: 0.25rem; position: relative;"
                  >
                    <div
                      style="padding-left: 8px; position: absolute; z-index: 990"
                    >
                      ระดับสต็อก {{ row.PERCENT }}%
                    </div>
                    <div
                      class="pl-2 text-left"
                      style="border-radius: 0.25rem;"
                      :class="row.PERCENT_CLASS"
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-12"><hr class="mt-2 mb-2" /></div>

          <div class="col-12 mb-1">
            <div class="row pl-1 pr-1">
           <!-- Stock Actions -->
          <div class="col-4 pl-1 pr-1" >
            <div class="text-center font-weight-bold">สต็อคคงเหลือ</div>    
            <div
              class="bg-danger p-0 d-flex flex-column"
              style="height: 60px; border-radius: 0.25rem;"
              @click="openModal('modal_count_1', rowIndex)"
            >
              <div
                class="w-100 text-center align-self-center font-weight-bold"
                :class="{ 'text-light': row.counted_at != null, 'text-white': row.counted_at === null }"
              >
                <template v-if="row.counted_at != today">
                  <span
                    v-if="row.BIG_LOCBAL > 999"
                    style="font-size: 2em; line-height: 60px;"
                  >
                    {{ row.BIG_LOCBAL +
                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                  </span>
                  <span
                    v-else
                    style="font-size: 3em; line-height: 60px;"
                  >
                    {{ row.BIG_LOCBAL +
                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                  </span>
                </template>
                <template v-if="row.counted_at == today">
                  <span
                    v-if="row.BIG_LOCBAL > 999"
                    style="font-size: 2em; line-height: 60px; color: yellow !important;"
                  >
                    {{ row.BIG_LOCBAL +
                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                  </span>
                  <span
                    v-else
                    style="font-size: 3em; line-height: 60px; color: yellow !important;"
                  >
                    {{ row.BIG_LOCBAL +
                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                  </span>
                </template>
              </div>
            </div>
          </div>

          <div class="col-4 pl-1 pr-1">
  <div class="text-center font-weight-bold">ราคาทุน</div>
  <div
    class="p-0 d-flex flex-column"
    style="height: 60px; border-radius: 0.25rem; background:#ddd; border:1px solid #aaa"
    @click="toggleShowCost"
  >
    <div
      class="w-100 text-center align-self-center font-weight-bold position-relative"
      :class="{ 'text-dark': row.LPURPR != null, 'text-secondary': row.LPURPR === null }"
    >
      <span v-if="!showCost" style="font-size: 1.4em; line-height: 60px;">
        กดดู
      </span>
      <span v-else style="font-size: 1.4em; line-height: 60px;">
        {{ numberFormat(row.LPURPR != null ? row._UNITPR : 0) }}
      </span>
    </div>
    <div v-if="showCost" class="text-center position-absolute" style="left:38px;bottom:0;font-size: 0.7em; color: gray;">
      {{ countdown }} วินาที
    </div>
  </div>
</div>

          <div class="col-4 pl-1 pr-1">
            <div class="text-center font-weight-bold">ราคาขาย</div>
            <div
              class="bg-success p-0 d-flex flex-column"
              style="height: 60px; border-radius: 0.25rem;border:1px solid #aaa"
              @click="openModal('modal_count_1', rowIndex)"
            >
              <div
                class="w-100 text-center align-self-center font-weight-bold"
                :class="{ 'text-white': row.SELLPR1 != null, 'text-secondary': row.SELLPR1 === null }"
              >
              <span
                    style="font-size: 1.4em; line-height: 60px;"
                > {{ numberFormat(row.SELLPR1 != null ? row.SELLPR1 : 0) }} </span>
              </div>
            </div>
          </div>
        </div>
          </div>
  
        
  
          

          
        </div>
      </div>
    </div>
  </template>


<script>
import StockService from "../StockService";

export default {
  props: {
    row: Object,
    rowIndex: Number,
    imageState: Boolean,
    stocks: Array,
    today: String,
    save: Function,
    dayText:Object,
    getValueFromAtom : Function,
    openModal: Function,
    addImage: Function,
    numberFormat:Function
  },
  data() {
    return {
      showCost: false, // Initial state for showing cost
      countdown: 0, // Countdown timer
      imageUrl: null, // Holds the fetched image URL
      isProcessing : false,
    };
  },
  mounted() {
    this.fetchImage();
  },
  methods: {
    toggleNotify(index) {
      this.stocks[index].notify = !this.stocks[index].notify;
      this.save(index);
    },
    toggleIsBsts(index) {
      this.stocks[index].is_bsts = !this.stocks[index].is_bsts;
      this.save(index);
    },
    toggleIsWatch(index) {
      this.stocks[index].is_watch = !this.stocks[index].is_watch;
      this.save(index);
    },
    toggleIsSell(index) {
      this.stocks[index].is_sell = !this.stocks[index].is_sell;
      this.save(index);
    },
    selectImage() {
      this.$refs.imageInput.click();
    },
    async handleImageChange(event) {
      this.isProcessing = true;
      const file = event.target.files[0];
      if (file) {
        try {
          // Upload the file to the Express API
          const formData = new FormData();
          formData.append("image", file);

          const response = await fetch("https://bg-remover-71653e19cfcf.herokuapp.com/remove-bg", {
            method: "POST",
            body: formData,
          });

          console.log(response)

          if (!response.ok) {
            throw new Error("Failed to remove background");
          }

          // Read the processed image as a Blob
          const blob = await response.blob();

          // Create a URL for the Blob to preview the image
          const imageUrl = URL.createObjectURL(blob);

          // Update the image preview and save the image data
          this.imageUrl = imageUrl;

          // Optionally, convert the Blob to base64 if needed
          const base64 = await this.convertToBase64(blob);
          this.row.image_data = base64;

          // Save the processed image
          await this.addImage(this.rowIndex, base64);
        } catch (error) {
          console.error("Error handling image change:", error);
        } finally{
          this.isProcessing = false
        }
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    getAvgSell(row) {
      if (row.AVG_SELL <= 0) return 0;
      if (row.AVG_SELL < 1 && row.AVG_SELL > 0) return "< 1";
      return row.AVG_SELL;
    },
    toggleShowCost() {
      this.showCost = true;
      this.startCountdown(3); // Start a 3-second countdown
    },
    startCountdown(seconds) {
      this.countdown = seconds;
      const interval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(interval);
          this.showCost = false; // Hide the cost after countdown
        }
      }, 1000);
    },
    async fetchImage() {
      try {
        const response =  await StockService.getImage(this.row.STKCOD);
        console.log(response)
        if (response.success) {
          this.imageUrl = response.image_data; // Use the fetched image data
        } else {
          console.log("Image fetch failed:", response.data.message);
        }
      } catch (error) {
        console.log("Error fetching image:", error);
      }
    },
    imageError() {
      this.imageUrl = "img/bsts.png"; // Fallback image in case of error
    },
  },
};
</script>

<style scoped>
.loader {
        transform: rotateZ(45deg);
        perspective: 1000px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        color: #000dff;
      }
        .loader:before,
        .loader:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: inherit;
          height: inherit;
          border-radius: 50%;
          transform: rotateX(70deg);
          animation: 1s spin linear infinite;
        }
        .loader:after {
          color: #FF3D00;
          transform: rotateY(70deg);
          animation-delay: .4s;
        }

      @keyframes rotate {
        0% {
          transform: translate(-50%, -50%) rotateZ(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotateZ(360deg);
        }
      }

      @keyframes rotateccw {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(-360deg);
        }
      }

      @keyframes spin {
        0%,
        100% {
          box-shadow: .2em 0px 0 0px currentcolor;
        }
        12% {
          box-shadow: .2em .2em 0 0 currentcolor;
        }
        25% {
          box-shadow: 0 .2em 0 0px currentcolor;
        }
        37% {
          box-shadow: -.2em .2em 0 0 currentcolor;
        }
        50% {
          box-shadow: -.2em 0 0 0 currentcolor;
        }
        62% {
          box-shadow: -.2em -.2em 0 0 currentcolor;
        }
        75% {
          box-shadow: 0px -.2em 0 0 currentcolor;
        }
        87% {
          box-shadow: .2em -.2em 0 0 currentcolor;
        }
      }
   
</style>