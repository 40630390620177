import axios from "axios";
//const url = "http://localhost:5000/api/stocks";
//const url = "https://stocks-api-337015.de.r.appspot.com/api/stocks";
//const url = (process.env.NODE_ENV == "development") ? "https://web-server-jade.vercel.app/api/stocks" : "https://stocks-api-337015.de.r.appspot.com/api/stocks";
//const url = (process.env.NODE_ENV == "development") ? "https://web-server-real.vercel.app/api/stocks" : "https://web-server-real.vercel.app/api/stocks";
 //const url = "https://web-server-real.vercel.app/api/stocks";

 const isDev = process.env.NODE_ENV == 'development'
 //const url = isDev ? "http://localhost:5555/api/stocks" : "https://web-server-real.vercel.app/api/stocks";
 const url = isDev ? "http://localhost:5555/api/stocks" : " https://client-web-api.thaisugars.com/api/stocks";




class StockService  {

    // โหลด Stocks ** To be Delete
    static getStocks(){
        return new Promise((resolve,reject) => {
            axios.get(url)
            .then(function (response) {
                const data = response.data;
                resolve(data);
            })
            .catch(function (error) {
              // handle error
              reject(error); 
            })
        })
    }

    // Refresh Stocks
    static refreshStocks(){
        return new Promise((resolve,reject) => {
            axios.get(url+'/refresh')
            .then(function (response) {
                const data = response.data;
                resolve(data);
            })
            .catch(function (error) {
              // handle error
              reject(error); 
            })
        })
    }

    static getImage(STKCOD) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${url}/getImage/${STKCOD}`) // Append STKCOD to the endpoint
                .then(function (response) {
                    const data = response.data;
                    resolve(data);
                })
                .catch(function (error) {
                    // Handle error
                    reject(error);
                });
        });
    }

 
    // เพิ่ม หรือ แก้ไข _products_config
    static saveConfig(index,data){
        return new Promise((resolve,reject) => {
            axios.post(url+'/config',{"index":index,"data":data})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
              // handle error
              reject(error);
            })
        })
    }

    static saveImage(index,data){
        return new Promise((resolve,reject) => {
            axios.post(url+'/image',{"index":index,"data":data})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
              // handle error
              reject(error);
            })
        })
    }

    static saveNote(index,data){
        return new Promise((resolve,reject) => {
            axios.post(url+'/noted',{"index":index,"data":data})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
              // handle error
              reject(error);
            })
        })
    }
    static deleteNote(index,data){
        return new Promise((resolve,reject) => {
            axios.post(url+'/DeleteNote',{"index":index,"data":data})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
              // handle error
              reject(error);
            })
        })
    }

    // เพิ่ม หรือ แก้ไข _products_count_stocks SAVE MAKE ORDER
    static saveMakeOrder(index,data){
     return new Promise((resolve,reject) => {

         axios.post(url+'/saveMakeOrder',{"index":index, "data" : data})
         .then(function (response) {
             resolve(response.data);
         })
         .catch(function (error) {
           // handle error
           reject(error);
         })
     })
     }
     // เพิ่ม หรือ แก้ไข _products_count_stocks DELETE MAKE ORDER
    static deleteMakeOrder(index,data){
     return new Promise((resolve,reject) => {
         axios.post(url+'/DeleteMakeOrder',{"index":index, "data" : data})
         .then(function (response) {
             resolve(response.data);
         })
         .catch(function (error) {
           // handle error
           reject(error);
         })
     })
  }
    // เพิ่ม หรือ แก้ไข _products_count_stocks
    static saveStocksCount(index,data,data2=null){
            return new Promise((resolve,reject) => {
                axios.post(url+'/stocksCount',{"index":index, "data" : data, "data2" : data2})
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                  // handle error
                  reject(error);
                })
            })
    }
            // เพิ่ม หรือ แก้ไข weight ทั้ง 3
            static saveStocksWeight(index,data){
                return new Promise((resolve,reject) => {
                    axios.post(url+'/stocksWeight',{"index":index, "data" : data})
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                      // handle error
                      reject(error);
                    })
                })
        }
        // เพิ่ม หรือ แก้ไข _products_count_stocks
        static saveStocksCountLast(index,data){
          return new Promise((resolve,reject) => {
              axios.post(url+'/stocksCountLast',{"index":index, "data" : data})
              .then(function (response) {
                  resolve(response.data);
              })
              .catch(function (error) {
                // handle error
                reject(error);
              })
          })
  }
    // เพิ่ม หรือ แก้ไข _products_count_stocks
    static deleteStocksCount(index,data){
        return new Promise((resolve,reject) => {
            axios.post(url+'/DeletestocksCount',{"index":index,"data":data})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
              // handle error
              reject(error);
            })
        })
     }

}

export default StockService