<template>
    <!-- Card -->
    <div v-if="!imageState" class="card" :class="row.BACKGROUND_CLASS">
        <div class="card-body pt-2 pb-2 px-3">
            <div class="row">
                <div class="col-12 pb-1 pr-2">
                    <h5 class="card-title text-truncate mb-0 font-weight-bold pt-1" style="color: #2b2301"
                        :title="`Index: ${rowIndex}`">
                        <b-icon v-if="row.order_frequency == 7" icon="star-fill" class="h5"
                            style="color: orange; margin: 0; padding: 0;"></b-icon>
                        {{ row.STKDES }}
                    </h5>
                    <h6 class="text-truncate pb-0 mb-0" style="color: #888; font-size: 0.9em;">
                        {{ row.SUPNAM_SUPPLIER != null ? row.SUPNAM_SUPPLIER : "ไม่มีข้อมูล" }}
                    </h6>
                </div>

                <div class="col-8">
                    <div class="row">
                        <!-- Icons -->
                        <div class="col-7 mt-1 mb-0 p-0 pl-3 d-flex align-items-center pt-1">
                            <div v-if="row.ordered_at == null || row.ordered_at != today"
                                class="p-0 text-left text-dark" style="position: relative;">
                                <b-icon icon="bell-fill" class="h4" :class="{ fadex: !row.notify }"
                                    style="cursor: pointer; margin: 0; padding: 0; padding-top: 2px; margin-right: 2px;"
                                    @click="toggleNotify(rowIndex)"></b-icon>
                                <b-icon icon="house-door-fill" class="h4" :class="{ fadex: !row.is_bsts }"
                                    style="cursor: pointer; margin: 0; padding: 0; margin-right: 2px;"
                                    @click="toggleIsBsts(rowIndex)"></b-icon>
                                <b-icon icon="lightning-fill" class="h4" :class="{ fadex: !row.is_watch }"
                                    style="cursor: pointer; margin: 0; padding: 0; margin-right: 2px;"
                                    @click="toggleIsWatch(rowIndex)"></b-icon>
                                    <b-icon icon="cart-plus-fill" class="h4" :class="{ fadex: !row.is_get_from_sor }"
                                    style="cursor: pointer; margin: 0; padding: 0; margin-right: 2px;"
                                    @click="toggleIsGetFromSor(rowIndex)"></b-icon>
                                <!-- <b-icon icon="award-fill" class="h4" :class="{ fadex: !row.is_sell }"
                                    style="cursor: pointer; margin: 0; padding: 0; margin-right: 2px;"
                                    @click="toggleIsSell(rowIndex)"></b-icon> -->
                                <!-- <div v-if="stocks[rowIndex].count_day != null"
                                    class="d-inline-block text-center font-weight-bold bg-dark"
                                    style="position: relative; top: -3px; width: 30px; border-radius: 0.25em; color: #eee;">
                                    {{ dayText[stocks[rowIndex].count_day] }}
                                </div> -->
                            </div>
                        </div>
                        <div v-if="tab_state != 3" class="col-5 mt-1 mb-0 p-0 d-flex align-items-center justify-content-end pr-2">
                            <div v-if="row.noted"
                                class="pl-2 pr-2 pt-1 pb-1 m-0 text-center text-dark m-0 h4 font-weight-bold text-truncate"
                                style="font-size:0.9em;border-radius: 0.25rem;background:#ccc">
                                {{ row.noted }}
                            </div>
                        </div>

                        <!-- Stock Status -->
                        <div class="col-12 pb-0 mb-0 pt-0 mt-1 mb-1">
                            <div style="line-height: 18px;">
                                <div>
                                    ขายเฉลี่ย{{ row.order_frequency == 30 ? "เดือน" : "สัปดาห์" }}ละ :
                                    <strong>{{ getAvgSell(row) }}</strong>
                                    {{ row.PQUCOD }}
                                </div>
                                <div>
                                    จำนวนลูกค้า
                                    <strong>{{ (row.CUSTOMERS?.length) }}</strong>
                                    ราย
                                </div>
                            </div>
                        </div>

                        <!-- Stock Level -->
                        <div class="col-12 mb-1 d-flex flex-column justify-content-center p-0 pl-4 pr-0">
                            <div class="pl-1 pr-0 w-100">
                                <div class="row p-0 text-center mr-2"
                                    style="border: 1px solid black; border-radius: 0.25rem; position: relative;">
                                    <div style="padding-left: 8px; position: absolute; z-index: 990">
                                        ระดับสต็อก {{ row.PERCENT }}%
                                    </div>
                                    <div class="pl-2 text-left" style="border-radius: 0.25rem;"
                                        :class="row.PERCENT_CLASS">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Stock Actions -->
                <div class="col-4 pl-2 pr-2 d-flex align-items-end pb-1">
                    <div class="bg-danger p-0 d-flex flex-column w-100" style="height: 90px; border-radius: 0.25rem;"
                        @click="openModal('modal_count_1', rowIndex)">
                        <div class="w-100 text-center align-self-center font-weight-bold"
                            :class="{ 'text-light': row.counted_at != null, 'text-secondary': row.counted_at === null }">
                            <template v-if="row.counted_at != today">
                                <span v-if="row.BIG_LOCBAL > 999" style="font-size: 2em; line-height: 90px;">
                                    {{ row.BIG_LOCBAL +
                                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                                </span>
                                <span v-else style="font-size: 3em; line-height: 90px;">
                                    {{ row.BIG_LOCBAL +
                                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                                </span>
                            </template>
                            <template v-if="row.counted_at == today">
                                <span v-if="row.BIG_LOCBAL > 999"
                                    style="font-size: 2em; line-height: 90px; color: yellow !important;">
                                    {{ row.BIG_LOCBAL +
                                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                                </span>
                                <span v-else style="font-size: 3em; line-height: 90px; color: yellow !important;">
                                    {{ row.BIG_LOCBAL +
                                    getValueFromAtom(row.express_DIFF, row.CFACTOR).BIG }}
                                </span>
                            </template>
                        </div>
                    </div>
                </div>


                <div v-if="tab_state==3" class="col-12  pl-2 pr-2">
                    <div class="row  pl-2 pr-2">
                    <template v-if="!row.is_noted">
                        <div class="col-8 pl-2 pr-2 pt-1">
                            <input v-model="row.noted" type="text" class="p-1 ml-1 text-center w-100 m-0 custom-input"
                                style="border:none!important;background:#ddd;border-radius:5px;" />
                        </div>
                        <div class="col-4 pl-1 pr-2 pt-1" style="cursor:pointer">
                            <div @click="addNote(rowIndex)"
                                class="p-1 ml-1 text-center font-weight-bold bg-success text-warning"
                                style="border-radius:5px;">ดำเนินการ</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-12 text-center pl-2 pr-2 pt-1" style="cursor:pointer">
                            <div @click="delNote(rowIndex)" class="p-1 ml-1 text-center font-weight-bold"
                                style="background:#ccc;padding:4px;font-weight:bold;border-radius:5px;">{{ row.noted }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>





            </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
    props: {
      row: Object,
      rowIndex: Number,
      imageState: Boolean,
      tab_state:Number,
      stocks: Array,
      dayText: Object,
      today: String,
      save: Function, // Receive the save function as a prop
      openModal: Function, // Receive the openModal function as a prop
      getValueFromAtom:Function,
      addNote:Function,
      delNote:Function
    },
    methods: {
      getAvgSell(row) {
        if (row.AVG_SELL <= 0) return 0;
        if (row.AVG_SELL < 1 && row.AVG_SELL > 0) return "< 1";
        return row.AVG_SELL;
      },
      toggleNotify(index) {
        this.stocks[index].notify = !this.stocks[index].notify;
        this.save(index); // Use the parent-provided save function
      },
      toggleIsBsts(index) {
        this.stocks[index].is_bsts = !this.stocks[index].is_bsts;
        this.save(index); // Use the parent-provided save function
      },
      toggleIsWatch(index) {
        this.stocks[index].is_watch = !this.stocks[index].is_watch;
        this.save(index); // Use the parent-provided save function
      },
      toggleIsGetFromSor(index) {
        this.stocks[index].is_get_from_sor = !this.stocks[index].is_get_from_sor;
        this.save(index); // Use the parent-provided save function
      },
      toggleIsSell(index) {
      this.stocks[index].is_sell = !this.stocks[index].is_sell;
      this.save(index);
    },
      
    
    },
  };
  </script>
  